<template>
    <section>
        <div v-loading="loading" class="f-15 overflow-auto custom-scroll" :class="rutaCedis ? 'height-cedis' : $route.name == 'admin.cedis.combos-kits' ? 'height-kits' :'height-productos'">
			<cargando v-if="cargando" />
            <ValidationObserver v-show="$route.name != 'admin.cedis.combos-kits'" ref="form1">
                <div class="row mx-0 m-3 justify-center overflow-hidden">
                    <div class="col-auto pr-0">
                        <div>
                            <el-tooltip placement="bottom" content="Haga clic aquí para editarla" effect="light">
                                <img :src="foto" class="border br-10 cr-pointer" style="height:160px;width:160px;" @click="modalEditarImagenProducto()" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col px-0 f-14">
                        <div class="d-flex flex-column justify-content-between mx-0">
                            <div class="col-12 my-2 text-general">
                                <p class="f-600">
                                    Creado por:
                                </p>
                                <p>
                                    {{ producto.creador }}
                                </p>
                                <p>
                                    {{ formatearFecha(producto.created_at,'D MMM Y hh:mmA') }}
                                </p>
                            </div>
                            <div class="col-12 my-2 text-general">
                                <p class="f-600">
                                    Actualizado:
                                </p>
                                <p>
                                    {{ producto.editor }}
                                </p>
                                <p>
                                    {{ formatearFecha(producto.updated_at,'D MMM Y hh:mmA') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col text-center text-general f-500">
                        Este producto es un kit
                        <el-switch v-model="model.kit" class="switch-red" :active-value="1" :inactive-value="0" :disabled="disableInputs" @change="cambiarTipoProducto(model.kit)" />
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                            <label class="ml-2 text-general">Nombre</label>
                            <!-- <el-input v-model="model.nombre" class="input-pr" size="small" maxlength="60" show-word-limit /> -->
                            <el-autocomplete
                            v-model="model.nombre"
                            :disabled="!$can('botones_productos_gestionar_producto') || disableInputs"
                            class="w-100"
                            value-key="nombre_completo"
                            :fetch-suggestions="querySearch"
                            placeholder="Nombre del producto"
                            :trigger-on-focus="false"
                            @select="setSugerencia"
                            />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{max:25}" name="presentación">
                            <label class="ml-2 text-general">Presentación</label>
                            <el-input v-model="model.presentacion" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="br-6" size="small" maxlength="25" show-word-limit />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{max:20}" name="sku">
                            <label class="ml-3 text-general">SKU</label>
                            <el-input v-model="model.sku" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="br-6" size="small" maxlength="20" show-word-limit />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{max:30}" name="barcode">
                            <label class="ml-3 text-general">Barcode</label>
                            <el-input v-model="model.barcode" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="br-6" size="small" maxlength="30" show-word-limit />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div v-if="(id_cedi_ruta? id_cedi_ruta:id_cedis_navbar)!==0" class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="`required`" name="precio">
                            <label class="ml-3 text-general">Precio</label>
                            <div class="d-middle">
                                <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                    {{ sigla_actual }}
                                </div>
                                <input-miles-moneda v-model="model.valor" :id-moneda="moneda_cedis" maxlength="13" :disabled="true" />
                                <div v-if="$can('boton_productos_editar_precio_producto')" class="col-auto px-0">
                                    <el-tooltip placement="bottom" content="Editar Precio" effect="light">
                                        <i class="icon-pencil-outline text-gr-general f-22 cr-pointer" @click="modalEditarPrecio" />
                                    </el-tooltip>
                                </div>
                                <div v-if="conCedisProducto" class="col-auto px-0">
                                    <el-tooltip v-if="$can('boton_productos_historial_precios_producto')" placement="bottom" content="Historia de cambios de precios de compra y venta" effect="light">
                                        <i class="icon-chart-line text-gr-general f-22 cr-pointer" @click="historialCambios()" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <label class="text-general ml-3">Descripción</label>
                        <el-input v-model="model.descripcion" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" type="textarea" :rows="3" maxlength="500" show-word-limit onkeypress="return event.keyCode != 13" />
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="unidad medida">
                            <label class="text-general ml-3">Unidad de medida de venta</label>
                            <el-select v-model="model.id_unidad" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" size="small" class="w-100">
                                <el-option
                                v-for="item in unidades"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="vender">
                            <label class="text-general ml-3">Vender por</label>
                            <el-select v-model="model.cantidad_tipo" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" size="small" class="w-100" @change="cambio_tipo">
                                <el-option
                                label="Unidad"
                                :value="1"
                                />
                                <el-option
                                label="Fracción"
                                :value="2"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{required:true, decimal:model.cantidad_tipo == 1 ? 0 : 2,max_value:9999.999,min_value:0.1}" name="cantidad aumentada click">
                            <label class="text-general ml-3">Cantidad aumentada click +</label>
                            <el-input v-model="model.cantidad_minima" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div v-if="$route.name == 'admin.cedis.productos'" class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="numeric|max_value:65000" name="cantidad maxima">
                            <label class="text-general ml-3">Cantidad maxima de venta</label>
                            <el-input v-model="model.maxima_compra_permitida" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs || maximaCompraIlimitada" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            <el-checkbox v-model="maximaCompraIlimitada" class="check-dark" @change="changeMaximaCompra">
                                Ilimitado
                            </el-checkbox>
                        </ValidationProvider>
                    </div>
                </div>
                <div v-if="$route.name == 'admin.cedis.productos'" class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{decimal:model.cantidad_tipo == 1 ? 0 : 1,max_value:99.9,min_value:0.1}" name="cantidad mínima">
                            <label class="text-general ml-3">Cantidad mínima de venta</label>
                            <el-input v-model="model.minima_compra_permitida" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <label class="text-general ml-3">Peso Volumétrico</label>
                        <div class="border px-3 br-6 cr-pointer d-middle" style="height:32px;" @click="seleccionarPesoVolumetrico">
                            <span class="text-general2 f-14">{{ producto.volumen || 'Sin volumen' }}</span> <i class="icon-angle-down text-general f-18 ml-auto" />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{ required:false, decimal:2, max_value: 9999.99, greaterThanZero: false }" name="peso">
                            <label class="text-general ml-3">Peso (Kg)</label>
                            <el-input v-model="model.peso" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="br-6" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div v-show="(id_cedi_ruta? id_cedi_ruta:id_cedis_navbar) != 0" class="row mx-0 m-3">
                    <div class="col-12 text-general f-600 f-16 mb-3 mt-4 pl-5">
                        Impuesto del producto
                    </div>
                    <div class="col-12 d-middle mb-3 text-general">
                        <!-- <input v-model="model.id_impuesto" :value="0" type="radio" class="option-input radio black" name="Efectivo" /> Ninguno -->
                        <el-radio v-model="model.id_impuesto" :label="0" class="radio-dark radio-check-icon">
                            Ninguno
                        </el-radio>
                    </div>
                    <div v-for="(impuesto, i) in impuestos" :key="i" class="col-12 d-middle mb-3 text-general">
                        <!-- <input v-model="model.id_impuesto" :value="impuesto.id" type="radio" class="option-input radio black" name="Efectivo" /> {{ impuesto.nombre }} ({{ agregarSeparadoresNumero(impuesto.porcentaje,1) }}%) -->
                        <el-radio v-model="model.id_impuesto" :label="impuesto.id" class="radio-dark radio-check-icon">
                            {{ impuesto.nombre }} ({{ agregarSeparadoresNumero(impuesto.porcentaje,1) }}%)
                        </el-radio>
                    </div>
                </div>

                <div class="row mx-0 m-3">
                    <div class="col">
                        <label class="text-general ml-3">Categorías</label>
                        <!-- <el-select v-model="value_categoria" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" size="small" filterable class="w-100" @change="agregar_categoria">
                            <el-option
                            v-for="(item) in categorias"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            :disabled="item.disabled"
                            />
                        </el-select> -->

                        <el-select v-model="value_categoria" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="w-100" size="small" filterable @change="agregar_categoria">
                            <el-option-group
                            v-for="(group,key) in categorias"
                            :key="key"
                            :label="group.nombre"
                            >
                                <el-option
                                v-for="(item,key2) in group.subcategorias"
                                :key="key2"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="item.disabled"
                                />
                            </el-option-group>
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(c,index) in categorias_aplicadas" :key="`key1${index}`" class="br-20 f-12 bg-general3 pl-2 text-white d-middle m-2">
                                {{ c.nombre }}
                                <i v-if="!disableInputs" class="icon-cancel f-20 cr-pointer ml-2" @click="remover_categoria(c.id)" />
                                <i v-else class="icon-cancel f-20 cr-pointer ml-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 m-3">
                    <div class="col">
                        <label class="text-general ml-3">Etiquetas</label>
						<el-select v-model="value_etiqueta" :disabled="!$can('botones_productos_gestionar_producto') || disableInputs" class="w-100" size="small" placeholder="Select" filterable @change="agregar_etiqueta">
                            <el-option-group
                            v-for="(group,key) in etiquetas"
                            :key="key"
                            :label="group.nombre"
                            >
                                <el-option
                                v-for="(item,key2) in group.etiquetas_hijas"
                                :key="key2"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="item.disabled"
                                />
                            </el-option-group>
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(e,index2) in etiquetas_aplicadas" :key="`key2${index2}`" class="br-20 f-12 bg-general3 pl-2 text-white d-middle m-1">
                                {{ e.nombre }}
                                <i v-if="!disableInputs" class="icon-cancel f-20 cr-pointer ml-2" @click="remover_etiqueta(e.id)" />
                                <i v-else class="icon-cancel f-20 cr-pointer ml-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col text-center text-general">
                        Imágenes adicionales del producto
                        <el-upload
                        action="#"
                        multiple
                        class="my-3"
                        :auto-upload="false"
                        accept="image/jpeg,image/x-png"
                        list-type="picture-card"
                        :on-change="agregar"
                        :on-remove="remover" style=""
                        :file-list="fileList"
                        :disabled="disableInputs"
                        >
                            <i class="el-icon-plus" />
                        </el-upload>
                    </div>
                </div>
            </ValidationObserver>
            <template v-if="$route.name == 'admin.cedis.combos-kits'">
                <div class="row mx-0 px-3 pt-4">
                    <img :src="producto.imagen" width="162" height="162" class="obj-cover border br-12" />
                    <div class="col text-general">
                        <p class="f-600">
                            Creado:
                        </p>
                        <p>
                            {{ producto.creador }}
                        </p>
                        <p>
                            {{ formatearFecha(producto.created_at,'D MMM Y hh:mmA') }}
                        </p>
                        <p class="f-600">
                            Actualizado:
                        </p>
                        <p>
                            {{ producto.editor }}
                        </p>
                        <p>
                            {{ formatearFecha(producto.updated_at,'D MMM Y hh:mmA') }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="text-general f-17 f-600">{{ producto.nombre }}</p>
                        <p class="text-general f-17 f-600 mt-3">{{ separadorNumero(producto.valor) }}</p>
                        <p class="text-general f-14 f-500 mt-3 "> {{ producto.descripcion }} </p>
                        <p class="text-general f-14 f-500 mt-3 "> {{ producto.presentacion }} </p>
                        <p class="text-general f-14 mt-3"> <b>Presentación:</b>  {{ producto.presentacion }} </p>
                        <p class="text-general f-14 mt-3"> <b>Unidad de medida:</b>  {{ producto.unidad }} </p>
                        <p class="text-general f-14 mt-3"> <b>Venta mínima:</b>  {{ producto.cantidad_minima }} </p>
                        <p class="text-general f-14 mt-4"> <b>Categorías:</b>  <span v-for="(categoria, c) in producto.categorias" :key="c">{{ categoria.nombre + ',' }} </span>  </p>
                        <p class="text-general f-14 mt-3"> <b>Etiquetas:</b>  <span v-for="(etiquetasProd, c) in producto.etiquetas" :key="c">{{ etiquetasProd.nombre + ',' }} </span>  </p>
                    </div>
                </div>
            </template>
            <contentLoader v-show="loading_general" />
        </div>
        <div v-show="$route.name != 'admin.cedis.combos-kits'" class="row mx-0 justify-center px-2 pt-2 border-top">
            <el-tooltip v-if="$can('boton_productos_duplicar_producto') || disableInputs" class="item" effect="light" content="Duplicar producto" placement="bottom">
                <div class="btn-action text-general cr-pointer d-middle-center border br-8" @click="duplicar_producto">
                    <i class="icon-content-duplicate f-20" />
                </div>
            </el-tooltip>
            <el-tooltip v-if="$can('boton_productos_eliminar_producto') || disableInputs" class="item" effect="light" content="Eliminar producto" placement="bottom">
                <div class="btn-action text-general mx-xl-2 mx-lg-1 cr-pointer border d-middle-center br-8" @click="open_eliminar_producto">
                    <i class="icon-trash-empty f-20" />
                </div>
            </el-tooltip>
            <div v-if="$can('botones_productos_gestionar_producto') || disableInputs" v-loading="loading" class="col-4 btn-general bg-general cr-pointer br-8 py-1 text-white d-middle-center" @click="editar_producto">
                Guardar
            </div>
        </div>

        <modalEliminar ref="modalEliminarProd" :titulo="titulo_eliminar" @eliminar="eliminar_producto">
            <div class="row mx-0 mb-2 justify-center text-center">
                {{ msg_eliminar }}
            </div>
        </modalEliminar>

        <modalCrearProducto ref="modalCrearProducto" :duplicar="true" @update="actualizar_listado" />
        <modalEditarPrecio ref="modalEditarPrecio" @actualizar="recargar_precio" />
        <modal-historial-cambios ref="HistorialCambios" />
        <modal-eliminar-producto ref="modalEliminarProducto" />
        <modal-peso-volumetrico ref="modalPesoVolumetrico" @actualizar="cambiar_volumen" />
        <modal-editar-imagen-producto ref="modalEditarImagenProducto" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Productos from "~/services/productos/productosAdmin"
import ProductosGeneral from "~/services/productos/productos"

export default {
    components: {
        modalCrearProducto: () => import('../partials/modalCrearProducto'),
        modalEditarPrecio: () => import('../partials/modalEditarPrecio'),
        modalHistorialCambios: () => import('../partials/modalHistorialCambios'),
        modalEliminarProducto: () => import('../partials/modalEliminarProducto'),
        modalPesoVolumetrico: () => import('../partials/modalPesoVolumetrico'),
        modalEditarImagenProducto: () => import('../partials/modalEditarImagenProducto')
    },
    data(){
        return {
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            model: {
                nombre: '',
                sku:null,
                barcode:null,
                kit:0,
                descripcion: '',
                id_unidad: '',
                id_impuesto: 0,
                peso: 0,
                cantidad_tipo: '',
                cantidad_minima: '',
                presentacion: '',
                valor: null,
                estado:0,
                maxima_compra_permitida:null,
                minima_compra_permitida:null

            },
            slimOptions: {
                ratio: '1:1',
                label:"Imagen Principal",
                minSize: { width: 90, height: 90 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            loading:false,
            loading_general:false,
            cargando:false,
            categorias: [],
            value_categoria:null,
            categorias_aplicadas:[],
            etiquetas: [],
            value_etiqueta:null,
            etiquetas_aplicadas:[],
            unidades: [],
            impuestos: [],
            producto:{},
            fileList:[],
            payload:{
                imagenes: [],
                ids_old: []
            },
            foto: null,
            conCedisProducto: false,
            titulo_eliminar: '¿Realmente desea eliminar este producto?',
            msg_eliminar: 'Esta acción no puede ser revertida.',
            maximaCompraIlimitada: false,
            copiaMaximaCompraPermitida: null,
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            monedas:'cedis/monedas',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar'

        }),
        sigla_actual(){
            const id_cedi = this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar
            let cedis = this.cedis.find(e => e.id== id_cedi )
            var sigla = '-'
            if(cedis){
                let moneda = this.monedas.find(e => e.id=== cedis.idm_moneda )
                if(moneda){
                    return sigla = moneda.sigla
                }
            }
            return sigla
        },
        moneda_cedis(){
            let cedis = this.cedis.find(e => e.id== this.id_cedis_navbar )
            var id_moneda_cedis = 0
            if(cedis){
                let moneda = this.monedas.find(e => e.id=== cedis.idm_moneda )
                if(moneda){
                    return id_moneda_cedis = moneda.id
                }
            }
            return id_moneda_cedis
        },
        rutaCedis(){
            return this.$route.name == 'admin.cedis.productos' || this.$route.name == 'tendero.productos'
        },
        disableInputs(){
            return (this.model.kit == 1 && this.$route.name==='admin.cedis.combos-kits')
        }
    },

    watch:{
        id_producto(val){
            if(val){
                this.value_categoria= null
                this.categorias_aplicadas= []
                this.value_etiqueta= null
                this.etiquetas_aplicadas= []
                this.detalle_producto()
            }
        },
        id_cedis(val){
            if(val){
                this.value_categoria= null
                this.categorias_aplicadas= []
                this.value_etiqueta= null
                this.etiquetas_aplicadas= []
                if(this.id_producto){
                    this.detalle_producto()
                }
            }
        }
    },
    methods:{
        actualizar_listado({categorias,producto}){
            this.$store.commit('productos/productos/push_producto_nuevo', producto)
        },
        cambiar_volumen(volumen){
            this.producto.volumen = volumen.nombre
            this.producto.id_volumen = volumen.id
        },
        seleccionarPesoVolumetrico(){
            const params = {
                id_producto: this.id_producto,
                id_volumen: this.producto.id_volumen
            }
            this.$refs.modalPesoVolumetrico.toggle(params);
        },
        modalEditarPrecio(){

            var obj = {
                valor: this.model.valor,
                id_promocion: this.producto.id_promocion,
                promo_valor: this.producto.promo_valor,
                promo_descuento: this.producto.promo_descuento,
                promo_valor_formateado: this.separadorNumero(this.producto.promo_valor),
                id_cedis: this.id_cedis_navbar,
                id_moneda: this.producto.idm_moneda,
                id_producto: this.id_producto
            }
            this.$refs.modalEditarPrecio.toggle(obj)
        },
        recargar_precio(newPrecio){
            this.model.valor = newPrecio
        },
        historialCambios(){
            var obj = {
                id_cedis: this.id_cedi_ruta,
                id_producto: this.id_producto
            }
            this.$refs.HistorialCambios.toggle(obj, this.model);
        },
        async detalle_producto(){
            try {
				this.cargando = true
                this.conCedisProducto = this.$route.name==='admin.cedis.productos'
                this.loading_general = true
                if(this.categorias.length === 0){
                    await this.datos_basicos()
                }
                const params ={
                    id_cedis: this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar,
                }
                const {data} = await Productos.detalle(this.id_producto, params)
                this.producto = data.producto
                this.$store.commit('productos/productos/setImagen', data.producto)
                if ((this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar) != 0){
                    this.impuestos = this.producto.impuestos
                }else{
                    this.impuestos = []
                }
                this.load_data(data)

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading_general = false
				this.cargando = false
            }
        },
        async load_data(data){
			try {
				this.foto = data.producto.imagen
				this.model = {
					nombre: data.producto.nombre,
					sku: data.producto.sku,
					barcode: data.producto.barcode,
					peso: data.producto.peso,
					kit: data.producto.kit,
					descripcion: data.producto.descripcion,
					id_unidad: data.producto.id_unidad,
					cantidad_tipo: data.producto.cantidad_tipo,
					cantidad_minima: data.producto.cantidad_tipo===1 ? parseInt(data.producto.cantidad_minima)  :  parseFloat(data.producto.cantidad_minima),
					maxima_compra_permitida: data.producto.maxima_compra_permitida,
					minima_compra_permitida: data.producto.cantidad_tipo===1 ? parseInt(data.producto.minima_compra_permitida) : parseFloat(data.producto.minima_compra_permitida),
					presentacion: data.producto.presentacion,
					valor:  data.producto.valor,
					estado:  data.producto.estado,
					id_impuesto: 0,
				}
	
				if(this.model.maxima_compra_permitida == 0){
					this.maximaCompraIlimitada = true;
					this.copiaMaximaCompraPermitida = this.model.maxima_compra_permitida;
				} else {
					this.maximaCompraIlimitada = false;
					this.copiaMaximaCompraPermitida = null;
				}
	
				if ((this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar) != 0){
					this.model.id_impuesto = data.producto.impuestos.length == 0 ? 0 :  data.producto.productos_impuestos &&  data.producto.productos_impuestos.length != 0 ?  data.producto.productos_impuestos[0].id : 0
				}

				for (let c of this.categorias){
					for (const sub of c.subcategorias){
						sub.disabled = false
						for (let ca of data.producto.categorias){
							if(sub.id === ca.id_categoria){
								this.categorias_aplicadas.push(sub)
								sub.disabled = true
							}
						}
					}
				}
				const etiquetasP = data.producto.etiquetas

				this.etiquetas.forEach(element => {
					element.etiquetas_hijas.forEach(item => {
						item.disabled = false;
						etiquetasP.forEach( i => {
							if(i.id == item.id){
								this.etiquetas_aplicadas.push(item)
								item.disabled = true;
							}
						})
					});
				});


				// para las imágenes inferiores
				this.fileList = []
				this.payload.imagenes= []
				this.payload.ids_old= []
				data.producto.fileList.forEach(async element => {
					this.fileList.push({name:element.id, url: element.imagen})
				});
			} catch (error) {
				return this.error_catch(error)
			}
        },

        async editar_producto(){
            try {
                this.loading = true

                const valid =  await this.$refs.form1.validate()

                if(valid){
                    if(this.categorias_aplicadas.length === 0){
                        this.notificacion('Advertencia','Por favor seleccione almenos una categoria','warning')
                        return
                    }
                    this.model.id_cedis = this.id_cedis_navbar

                    await this.obtenerImagenesNuevasProducto()
                    this.model.file_new = this.payload.imagenes
                    this.model.file_old= this.payload.ids_old

                    this.model.categorias = JSON.stringify(this.categorias_aplicadas)
                    this.model.etiquetas = JSON.stringify(this.etiquetas_aplicadas)
                    this.model.sku = this.model.sku == '' ? null : this.model.sku
                    this.model.barcode = this.model.barcode == '' ? null : this.model.barcode
                    this.model.descripcion = this.model.descripcion == '' ? null : this.model.descripcion
                    this.model.presentacion = this.model.presentacion == '' ? null : this.model.presentacion
                    this.model.maxima_compra_permitida = this.model.maxima_compra_permitida == '' ? null : this.model.maxima_compra_permitida
                    this.model.minima_compra_permitida = this.model.minima_compra_permitida == null ? 1 : this.model.minima_compra_permitida
                    var newModel = this.crear_formData(this.model)
                    const {data} = await Productos.actualizar(this.id_producto, newModel)

                    this.notificacion('Mensaje','Producto actualizado correctamente','success')

                    this.$store.commit('productos/productos/update_productos', data.producto)
                    const lastId = this.id_producto
                    this.$store.commit('productos/productos/set_id_producto', null)
                    setTimeout(() => {
                        this.$store.commit('productos/productos/set_id_producto', lastId)
                    }, 500)



                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        cambio_tipo(){
            this.model.cantidad_minima = null
            this.model.minima_compra_permitida = null
        },
        agregar_categoria(id){
            let item = null;
            for (const cat of this.categorias){
                item = cat.subcategorias.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = true
            this.categorias_aplicadas.push(item)
            this.value_categoria = null
        },
        remover_categoria(id){
            let item = null;
            for (const cat of this.categorias){
                item = cat.subcategorias.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = false
            this.categorias_aplicadas.splice(this.categorias_aplicadas.indexOf(item), 1)
        },
        agregar_etiqueta(id){
			let item = null;
            for (const cat of this.etiquetas){
                item = cat.etiquetas_hijas.find(e => e.id === id)
                if(item) break
            }
            item.disabled = true
            this.etiquetas_aplicadas.push(item)
            this.value_etiqueta = null
        },
        remover_etiqueta(id){
			let item = null;
            for (const cat of this.etiquetas){
				item = cat.etiquetas_hijas.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = false
            this.etiquetas_aplicadas.splice(this.etiquetas_aplicadas.indexOf(item), 1)

        },
        async datos_basicos(){
            try {

                const {data} = await Productos.selects_admin()
                this.categorias =  data.categorias
                this.etiquetas =  data.etiquetas
                this.unidades =  data.unidades

            } catch (e){
                this.error_catch(e)
            }
        },
        open_eliminar_producto(){
            //  Modal Eliminar Producto;

            this.conCedisProducto = this.$route.name==='admin.cedis.productos' || (this.id_cedi !==0)
            this.titulo_eliminar = this.conCedisProducto? 'Retirar producto':'¿Realmente desea eliminar este producto?'
            this.msg_eliminar = this.conCedisProducto? '¿Realmente desea retirar el producto de este cedis? No se eliminará del catalogo de productos':' Esta acción no puede ser revertida.'
            if (this.rutaCedis){
                this.$refs.modalEliminarProd.toggle()
            }else{
                this.$refs.modalEliminarProducto.toggle(this.producto);
            }
        },
        async eliminar_producto(){
            try {
                if(this.conCedisProducto){
                    var {data} = await Productos.inactivarCedisProducto({id_producto: this.id_producto, id_cedis: this.id_cedi_ruta})
                }else{
                    var {data} = await Productos.eliminar(this.id_producto)
                }
                this.$refs.modalEliminarProd.toggle()
                this.$store.commit('productos/productos/delete_producto', this.id_producto)
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.notificacion('Mensaje','Producto removido exitosamente','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        duplicar_producto(){
            // modalCrearProducto
            var model = this.model
            const info = {
                model: model,
                fileList: this.fileList,
                categorias: this.categorias_aplicadas,
                etiquetas: this.etiquetas_aplicadas,
                foto: this.foto,
                id_producto_old: this.id_producto
            }
            this.$refs.modalCrearProducto.toggle(info)

            // this.$refs.modalDuplicarProducto.toggle(this.producto)
        },
        capturarSubidaImagenes(archivo, listaArchivos){
            this.adjuntosImagenes = listaArchivos;
        },
        agregar(file,listaArchivos){
            this.fileList = listaArchivos
        },
        remover(file, listaArchivos){
            this.fileList = listaArchivos
            // this.fileList.splice(file,1)
        },
        async obtenerImagenesNuevasProducto(){
            if (_.isEmpty(this.fileList)) return;

            this.payload.imagenes = [];

            for await (let x of this.fileList){
                if(x.raw){

                    this.payload.imagenes.push(x.raw);
                }else{
                    this.payload.ids_old.push(x.name);
                }
            }
        },
        async querySearch(string, cb){
            const {data} = await ProductosGeneral.getCoincidencias({text: string})
            cb(data.productos);
        },
        async cambiarTipoProducto(tipo_kit){
            try {
                let datos = {
                    tipo : tipo_kit,
                    id_producto : this.id_producto
                }

                let {data} = await Productos.cambiarTipoProducto(datos)

                this.$store.commit('productos/productos/set_is_kit', (tipo_kit==1))
                this.notificacion('Mensaje','Cambio de tipo de producto exitoso','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        modalEditarImagenProducto(){
            this.$refs.modalEditarImagenProducto.toggle(this.id_producto,this.foto)
        },
        changeMaximaCompra(){
            if(this.maximaCompraIlimitada){
                this.copiaMaximaCompraPermitida = this.model.maxima_compra_permitida;
                this.model.maxima_compra_permitida = 0;
            } else {
                this.model.maxima_compra_permitida = this.copiaMaximaCompraPermitida;
            }
        },
        setSugerencia(item){
            this.model.nombre = item.nombre
            this.model.presentacion = item.presentacion
            //console.log(item);
        }
    }
}
</script>
<style lang="scss" scoped>
.height-cedis{
    height: calc(100vh - 295px);
}
.height-productos{
    height: calc(100vh - 179px);
}
.height-kits{
    height:calc(100vh - 260px);
}
</style>
